import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { map } from 'rxjs/operators';
import { Project } from 'src/app/Models/project.model';
import { ProjectService } from 'src/app/project/Services/project.service';

@Component({
  selector: 'app-projecten',
  templateUrl: './projecten.component.html',
  styleUrls: ['./projecten.component.scss'],
})

// TODO: NameChange naar engelse benaming..
export class ProjectenComponent implements OnChanges {
  @Input() projectId?: number;
  projects!: Project[];
  constructor(private projectService: ProjectService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['projectId']) {
      this.projectService
        .GetActiveProject()
        .pipe(map((x) => x.filter((project) => project.id !== this.projectId)))
        .subscribe((projects) => (this.projects = projects));
    }
  }
}
