
<div class="container">
    <div>
        <h1>Stappenplan</h1>
        <ul>
            <li>
                Vooronderzoek aan de hand van kadasterplan, Geopunt vlaanderen, Atlas buurtwegen
            </li>
            <li>
                Enkel door een afspraak op het terrein kan de juistheid van de mogelijkheden worden ingeschat. Daarbij wordt uiteraard rekening gehouden met aanpalende buren, gabarit, waterlopen, voor -en achterbouwlijn, enz..
            </li>
            <li>
                Een verkavelingsontwerp op maat afgetoetst met de stedenbouwkundige dienst
            </li>
            <li>
                Kosten-Batenanalyse op maat
            </li>
            <li>
                Stedenbouwkundige plannen indienen
            </li>
            <li>
                Na goedkeuring plannen wordt de de infrastructuur uitgerust
            </li>
            <li>
                Verkoop van de gronden inclusief woning
            </li>
            <li>
                Deze professionele werkzaamheden worden door ons gecoördineerd, opgevolgd én voorgefinancierd.  In het belang van de klant werken wij steeds met een open boekhouding. Kortom géén verrassingen. 

            </li>
        </ul>        
    </div>
</div>



