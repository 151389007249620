import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as Leaflet from 'leaflet';
import { Coordinates } from 'src/app/Models/coordinates.model';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnChanges {
  @Input() coordinates?: Coordinates;
  @Input() name?: string;

  private map!: Leaflet.Map;
  private marker!: Leaflet.Marker;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['coordinates']) {
      const lat = this.coordinates?.latitude;
      const long = this.coordinates?.longitude;

      if (!lat || !long) return;

      if (!this.map) {
        this.initMap(lat, long);
        return;
      }

      const latLng = Leaflet.latLng(lat, long);
      this.marker.setLatLng(latLng);
      this.map.panTo(latLng);
    }
  }

  private initMap(lat: number, long: number): void {
    this.map = Leaflet.map('map', {
      center: [lat, long],
      zoom: 16,
    });

    const tiles = Leaflet.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        maxZoom: 18,
        minZoom: 3,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );

    tiles.addTo(this.map);

    this.marker = Leaflet.marker(Leaflet.latLng(lat, long), {
      icon: new Leaflet.Icon({
        iconSize: [60, 60],
        iconAnchor: [13, 41],
        iconUrl: 'assets/images/BeauHouse_Marker.svg',
      }),
      title: this.name,
    });

    this.marker.addTo(this.map);
  }
}
