import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent implements OnInit {
  isLoggedIn = false;
  isMenuActive= false;
  
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isAuthenticated();
    this.authService.getNotifyWhenLogin.subscribe(() => this.isLoggedIn = true);
  }

  logOut(): void {
    this.authService.logOut();
    this.isLoggedIn = false;
  }

  toggle() : void {
    this.isMenuActive = !this.isMenuActive;
    
  }

}

