import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'appnewproject',
  templateUrl: './newproject.component.html',
  styleUrls: ['./newproject.component.scss']
})
export class NewProjectComponent implements OnInit {

  newprojectForm = new UntypedFormGroup({

    NameProject: new UntypedFormControl(),
    City: new UntypedFormControl(),
    Description: new UntypedFormControl(),
    AddedValue_1: new UntypedFormControl(),
    AddedValue_2: new UntypedFormControl(),
    AddedValue_3: new UntypedFormControl(),
    AddedValue_4: new UntypedFormControl(),
    AddedValue_5: new UntypedFormControl(),
    Coverphoto: new UntypedFormControl()
  })
  cd: any;

  onSubmit() {
    console.log(this.newprojectForm.value);
  }

  constructor(

  ) {
  }

  ngOnInit(): void {
  }

}

