import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LightgalleryModule } from 'lightgallery/angular';
import { CarouselComponent } from './components/carousel/carousel.component';
import { VraagProjectComponent } from './components/CTA/vraag-project/vraag-project.component';
import { MapComponent } from './components/map/map.component';
import { MasonryComponent } from './components/masonry/masonry.component';
import { ProjectComponent } from './components/project/project.component';
import { ProjectenComponent } from './components/projecten/projecten.component';
import { ShortProjectComponent } from './components/short-project/short-project.component';
import { WooneenhedenComponent } from './components/wooneenheden/wooneenheden.component';

@NgModule({
  declarations: [
    CarouselComponent,
    WooneenhedenComponent,
    ProjectenComponent,
    VraagProjectComponent,
    MasonryComponent,
    ProjectComponent,
    ShortProjectComponent,
    MapComponent,
  ],
  imports: [CommonModule, LightgalleryModule, RouterModule, LeafletModule],
  exports: [
    CarouselComponent,
    WooneenhedenComponent,
    ProjectenComponent,
    VraagProjectComponent,
    MasonryComponent,
    ProjectComponent,
    ShortProjectComponent,
    MapComponent,
  ],
})
export class SharedModule {}
