<div class="flex-container"><h2>
    Projectontwikkeling | Projecten
</h2>
</div>
<div class="flex-container">
    <table>
        
    </table>
    <table>
        <thead>
            <tr><button class="mb75 btn-blue btn-wide">Nieuw project</button></tr>
            <tr>
                <th class="w75">Status</th>
                <th class="w175">Naam project</th>
                <th class="w175">Plaats</th>
                <th class="w275">Beschrijving</th>
                <th class="w75"></th>
                <th class="w75"></th>
            </tr>
        </thead>
        <tbody>
            <!-- <tr class="pretty p-image p-plain state"><input type="checkbox" /><div><img class="image" src="img/checked/004.png"></div></tr> -->
            <tr>
                <td>Online</td>
                <td>De Waegemakerij</td>
                <td>Ooike</td>
                <td>Wil je in het groen en landelijk wonen...</td>
                <td><button class="btn-green">Wijzigen</button></td>
                <td><button class="btn-red">Verwijderen</button></td>
            </tr>
            <tr>
                <td>Online</td>
                <td>Tindeke</td>
                <td>Mullem</td>
                <td>Wil je in het groen en landelijk wonen...</td>
                <td><button class="btn-green">Wijzigen</button></td>
                <td><button class="btn-red">Verwijderen</button></td>
            </tr>
            <tr>
                <td>Online</td>
                <td>Boeregem</td>
                <td>Nazareth</td>
                <td>Wil je in het groen en landelijk wonen...</td>
                <td><button class="btn-green">Wijzigen</button></td>
                <td><button class="btn-red">Verwijderen</button></td>
            </tr>
        </tbody>
    </table>

</div>