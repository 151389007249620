<div class="flex">
    <form [formGroup]="newprojectForm" (ngSubmit)="onSubmit()" class="newprojectform">
        <h2>Voeg een nieuw project toe</h2>
        <input name="Nameproject" type="text" formControlName="NameProject" class="no-border"
            placeholder="Naam van project" />
        <input name="City" type="text" formControlName="City" class="no-border" placeholder="Gemeente/stad" />
        <textarea name="Description" type="text" formControlName="Description" class="no-border"
            placeholder="Omschrijving project"></textarea>
        <input id="AddedValue_1" name="AddedValue_1" formControlName="AddedValue_1" type="text" class="no-border"
            placeholder="Meerwaarde 1" />
        <input id="AddedValue_2" name="AddedValue_2" formControlName="AddedValue_2" type="text" class="no-border"
            placeholder="Meerwaarde 2" />
        <input id="AddedValue_3" name="AddedValue_3" formControlName="AddedValue_3" type="text" class="no-border"
            placeholder="Meerwaarde 3" />
        <input id="AddedValue_4" name="AddedValue_4" formControlName="AddedValue_4" type="text" class="no-border"
            placeholder="Meerwaarde 4" />
        <input id="AddedValue_5" name="AddedValue_5" formControlName="AddedValue_5" type="text" class="no-border"
            placeholder="Meerwaarde 5" />

        <div class="">
            <section>
                <h2>Upload foto/video</h2>
                <div class="flex">
                    <div class="inputlabel">
                        <label for="Cover">
                            Coverfoto
                        </label>
                        <input id="Cover" name="Cover" type="file" />
                    </div>
                    <div>
                        <label for="Photosproject">
                            Foto's project
                        </label>
                        <input id="Photosproject" name="Photos" type="file" />
                    </div>
                    <div>
                        <label for="Photos">
                            Foto's omgeving
                        </label>
                        <input id="Photos" name="Photos" type="file" />
                    </div>
                    <div>
                        <label for="Video">
                            Video
                        </label>
                        <input id="Video" name="Video" type="file" />
                    </div>
                    <div>
                        <label for="VideoCover">
                            Vink dit aan als de video als eerste op de banner moet afspelen
                        </label>
                        <input type="checkbox" name="VideoCover" id="VideoCover" />
                    </div>
                </div>
            </section>
            <section class="hidden">
                <h2>Coördinaten Lambert</h2>
                <div>
                    <label for="LamLat">
                        Breedteligging
                    </label>
                    <input id="LamLat" name="LamLat" type="number" />
                </div>
                <div>
                    <label for="LamLong">
                        Lengteligging
                    </label>
                    <input id="LamLong" name="LamLong" type="number" />
                </div>
            </section>
        </div>

        <button class="button" type="submit">Verzenden</button>
        <button class="button" type="cancel">Annuleer</button>
    </form>

    <div class="preview overview flex flex-row">
        <div class="imageleft">
            <img src="/assets/images/Nazareth.jpg">
        </div>
        <div class="textleft">
            <div>
                <h2>{{newprojectForm.value.NameProject}} | {{newprojectForm.value.City}}</h2>
                <p>
                    {{newprojectForm.value.Description}}
                </p>
                <button>Bekijk project</button>
            </div>
        </div>
    </div>
</div>