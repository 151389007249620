<div class="container">
    <h1>Beau House Company @ your service</h1>

    <p>
        ‘Beau House Company’ kent zijn oorsprong uit de befaamde architecturale stijlstroming ‘Bauhaus’. 
        Een stijlstroming (1919-1932) welke een ongekende faam verwierf met architecten zoals Frank Lloyd Wright, Ludwig Mies Van Der Rohe, Eileen Gray,.. 
        Een no-nonsense bouwstijl gekenmerkt door zijn strakke en eenvoudige vormgeving, geometrische patronen, abstracte vormen en een helder kleurgebruik. 
        Zo is ons logo ook ontstaan, gebaseerd op een glasraam van de Coonley House.
    </p>

    <p>
        Meer dan dertig jaar lang inmiddels voert landmeter-expert Frank Reyné (beëdigd door de rechtbank) zijn opmetingen, verkavelingen, wegenisontwerpen 
        maar tevens ook waardebepalingen van vastgoed uit. Gaandeweg ontwikkelde hij een zesde zintuig in deze materie. De passie voor 
        schoonheid en eenvoud, architectuur en ruimtelijke ordening zijn begrippen die hem dagelijks motiveren. Zo besloot hij om zelf 
        bouwcoördinator te worden en zijn stempel te kunnen drukken op mooiere bouwprojecten met een even degelijke en kwalitatieve architectuur.
    </p>

    <p>
        Zijn copain Tom De Maeseneire leerde Frank een vijftiental jaar terug kennen. 
        Vanaf dag 1 ontstond er een bepaalde symbiose op vlak van het ontwerpen en uitdenken van toekomstige verkavelingen. 
        Geruggesteund door een vooropleiding interieurarchitectuur en 15 jaar ervaring in de vastgoedmakelaardij besloot Tom 
        om samen met Frank de uitdaging van bouwcoördinator aan te gaan en zo ontstond ‘Beau House Company’ al snel…..
    </p>
</div>