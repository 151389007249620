import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PropertyDetail } from 'src/app/Models/property-detail.Model';
import { Property } from 'src/app/Models/property.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  private readonly baseUrl = `${environment.apiBaseUrl}/Property`;

  constructor(private http: HttpClient) { }

  GetProperty(propertyId: number): Observable<PropertyDetail> {
    const url = `${this.baseUrl}/${propertyId}`;
    return this.http.get<PropertyDetail>(url);
  }

  GetProperties(): Observable<Property[]> {
    const url = `${this.baseUrl}`;
    return this.http.get<Property[]>(url);
  }

  GetPropertiesFromProject(projectId: number): Observable<Property[]> {
    const url = `${this.baseUrl}/FromProject/${projectId}`;
    return this.http.get<Property[]>(url);
  }
}
