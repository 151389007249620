import { Injectable } from '@angular/core';


import { HttpClient } from '@angular/common/http';
import { Credentials } from './models/credentials';
import { BearerToken } from './models/bearer-token';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly baseUrl = environment.apiBaseUrl;
  getNotifyWhenLogin: Subject<string> = new Subject();

  constructor(private http: HttpClient) { }

  login(credentials: Credentials): Observable<BearerToken> {
    return this.http.post<BearerToken>(`${this.baseUrl}/Login`, credentials);
  }

  setSession(res: BearerToken): void {
    const expiresAt = res.expires;
    localStorage.setItem('token', res.token);
    localStorage.setItem('expires_at', expiresAt);
    this.getNotifyWhenLogin.next();
  }


  logOut(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('expires_at');
  }

  isAuthenticated(): boolean {
    const expirationDate = localStorage.getItem('expires_at');
    let expiresAt;

    if (expirationDate != null) {
      expiresAt = new Date(expirationDate);
    }

    if (expiresAt && new Date() < expiresAt) {
      return true;
    }
    this.logOut();
    return false;
  }

}
