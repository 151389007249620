import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
import { Property } from 'src/app/Models/property.model';
import { PropertyService } from 'src/app/project/Services/property.service';

@Component({
  selector: 'app-wooneenheden',
  templateUrl: './wooneenheden.component.html',
  styleUrls: ['./wooneenheden.component.scss']
})
export class WooneenhedenComponent implements OnInit {

  @Input() propertyId?: number;
  @Input() projectId!: number;
  properties!: Property[];
  constructor(private propertyService: PropertyService) { }

  ngOnInit(): void {
    if (this.projectId == null) {
      return;
    }
    this.propertyService.GetPropertiesFromProject(this.projectId)
      .pipe(map(x => x.filter(property => property.id !== this.propertyId)))
      .subscribe(properties => this.properties = properties);
  }


}
