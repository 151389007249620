<div class="container-small" *ngIf="properties && properties.length > 0">
  <div>
    <h1>Bekijk ook onze andere wooneenheden binnen dit project</h1>
    <div class="flex nav-proj-bottom-vert wrap">
      <a class="c-button" [routerLink]="['/project', projectId , 'pand', property.id]"
        *ngFor="let property of properties">
        {{ property.reference }}
      </a>
    </div>
    <div>
      <!--<P>Vragen rond een lot? <br />Neem dan gerust contact op.<br />We helpen u graag verder.<br /><br />Frank |
        0475 26 28 28<br />Tom | 0475 11 01 01 </P>-->
    </div>
  </div>
</div>