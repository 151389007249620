<footer>
  <div class="flex-container link-black">
    <div class="edge1">
      <img src="assets/images/BeauHouse.png" width="180px" height="180px">
    </div>
    <div class="edge2">
      <h5>Kantoor Nazareth</h5>
      <p>Frank Reyné</p>
      <p>Oudenaardseheerweg 14A</p>
      <p>9810 Nazareth</p>
      <br />
      <p>0475 26 28 28</p>
      <p>frank@beau-house.be</p>
      <br />
      <h5>Openingsuren</h5>
      <p>Op afspraak</p>
    </div>
    <div class="edge3">
      <h5>Kantoor Gavere</h5>
      <p>Tom De Maeseneire</p>
      <p>Scheldestraat 4</p>
      <p>9890 Gavere</p>
      <br />
      <p>0475 11 01 01</p>
      <p>tom@beau-house.be</p>
    </div>
    <div class="edge2">
      <a href="https://www.facebook.com/Beau-House-company-101556418501728" target="_blank">
        <h5>Volg ons op Facebook</h5>
      </a>
      <div>
        <p>disclaimer</p>
      </div>
      <div>
        <p>privacy</p>
      </div>
    </div>
  </div>


  <div class="border-top-white">
    <div class="flex-container">
      <div class="">
        <div class="">
          &copy; {{ year }} | Beau House Company
        </div>
      </div>
    </div>
  </div>
</footer>
