import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projectontwikkeling',
  templateUrl: './projectontwikkeling.component.html',
  styleUrls: ['./projectontwikkeling.component.scss']
})
export class ProjectontwikkelingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
