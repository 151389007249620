import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Project } from 'src/app/Models/project.model';
import { ProjectService } from 'src/app/project/Services/project.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  year: number = new Date().getFullYear();
  pictures: string[] = [];
  activeProjects: Project[] = [];
  soldProjects: Project[] = [];

  constructor(private projectService: ProjectService) { }

  ngOnInit(): void {
    forkJoin([this.projectService.GetActiveProject(), this.projectService.GetSoldProject()])
      .subscribe((projects) => {
        this.activeProjects = projects[0];
        this.soldProjects = projects[1];

        const activePictures = this.getFirstPictureForeachProjectIfExists(this.activeProjects);
        const soldPictures = this.getFirstPictureForeachProjectIfExists(this.soldProjects);

        this.pictures = [...activePictures, ...soldPictures];
      });

  }

  scroll(el: HTMLElement): void {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  private getFirstPictureForeachProjectIfExists(projects: Project[]): string[] {
    return projects
      .filter(x => x.picturesUrl.length > 0)
      .map(x => x.picturesUrl[0]);
  }

}

