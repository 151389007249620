import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { AdminLayoutComponent } from './components/admin-layout/admin-layout.component';
import { HomeComponent } from './components/home/home.component';
import { MenuComponent } from './components/menu/menu.component';
import { ProjectontwikkelingComponent } from './components/projectontwikkeling/projectontwikkeling.component';
import { ListprojectsComponent } from './project/components/listprojects/listprojects.component';
import { NewProjectComponent } from './project/components/newproject/newproject.component';


const routes: Routes = [
  {
    path: '',
    component: MenuComponent,
    children:
      [
        { path: '', pathMatch: 'full', component: HomeComponent },
        { path: 'about', pathMatch: 'full', component: AboutComponent },
        { path: 'contact', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule) },
        { path: 'project', loadChildren: () => import('./project/project.module').then(m => m.ProjectModule) },
        { path: 'login', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
        { path: 'projectontwikkeling', pathMatch: 'full', component: ProjectontwikkelingComponent },
      ]
  },

  {
    path: 'admin',
    component: AdminLayoutComponent,
    children:
      [
        { path: 'listprojects', pathMatch: 'full', component: ListprojectsComponent },
        { path: 'newproject', pathMatch: 'full', component: NewProjectComponent },
      ]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
