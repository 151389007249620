<div class="c-project" [class.c-project--even]="isEven">
  <div class="c-project__detail" [class.c-project__detail--even]="isEven">
    <h2>{{project.reference}} | {{project.city}}</h2>
    <p>{{project.description}}
    </p>
    <div *ngIf="isSold" class="c-project__sold cut-text">Verkocht</div>
    <a *ngIf="!isSold" class="c-button" [routerLink]="['/project', project.id]">Bekijk project</a>
  </div>

  <img *ngIf="project.picturesUrl && project.picturesUrl.length > 0" [src]="project.picturesUrl[0]"
    class="c-project__image" />

</div>
