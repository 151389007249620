import { Component, Input, OnInit } from '@angular/core';
import { Project } from '../../../Models/project.model';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  @Input() project!: Project;
  @Input() isSold!: boolean;
  @Input() isEven = false;

  imageUrl!: string;
  constructor() { }

  ngOnInit(): void {
    this.imageUrl = `path_to_picture/${this.project?.picturesUrl[0]}`;
  }

}
