import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectDetail } from 'src/app/Models/project-detail.model';
import { environment } from 'src/environments/environment';
import { Project } from '../../Models/project.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private readonly baseUrl = `${environment.apiBaseUrl}/Project`;

  constructor(private http: HttpClient) { }

  GetSoldProject(): Observable<Project[]> {
    const url = this.baseUrl + '/GetSoldProject';
    return this.http.get<Project[]>(url);
  }

  GetActiveProject(): Observable<Project[]> {
    const url = this.baseUrl + '/GetActiveProject';
    return this.http.get<Project[]>(url);
  }

  GetProject(projectId: string): Observable<ProjectDetail> {
    const url = `${this.baseUrl}/${projectId}`;
    return this.http.get<ProjectDetail>(url);
  }
}
