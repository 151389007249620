import { Component, Input, OnInit } from '@angular/core';
import { Project } from 'src/app/Models/project.model';

@Component({
  selector: 'app-short-project',
  templateUrl: './short-project.component.html',
  styleUrls: ['./short-project.component.scss']
})
export class ShortProjectComponent implements OnInit {

  @Input() project!: Project;
  @Input() isSold = false;
  constructor() { }

  ngOnInit(): void {
  }

}
