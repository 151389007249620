<!-- Carousel -->
<div class="block vw100 carousel posrel">
  <app-carousel [slides]="pictures"></app-carousel>
  <div class="overlay-pres link widget-title">
    <h3>ALS JIJ HET KAN DROMEN, <br />
      KUNNEN WIJ HET BOUWEN
    </h3>
  </div>

  <div class="overlay-arrowdown" (click)="scroll(missie)">
    <i class="material-icons">
      expand_more
    </i>
  </div>

</div>

<div class="flex-container pt flex-custom">
  <!-- Inleiding -->
  <div>
    <section #missie class="center vw-50 margin-center">
      <h1 class="text-center alata">________ <br /> <br /> <br />
        Van projectontwikkeling tot bouwcoördinatie</h1>
      <p class="text-center">
        Wij bieden u prachtige bouwloten aan vanuit onze jarenlange ervaring in projectontwikkeling.
        Eén van onze gerenommeerde architecten ondersteunt u en dit vanaf het allereerste voorontwerp tot het opleveren van de woning.<br/>
        Offertes worden voor u verzameld en aan u voorgelegd. 
        U kan bouwen op ons professioneel advies, 
        we sturen de (onder) aannemers aan 
        en volgen voor u het bouwproces op. <br/>
        Kwalitatief werk voor de correcte prijs, daarmee willen we zeggen dat er enkel wordt samengewerkt met de betere lokale aannemer
        Doordat u rechtstreeks met de aannemers contracteert geniet u altijd van de 10-jarige waarborg.
        Voor deze diensten vragen we enkel 3% van de bouwkost met een minimum van een wind -en waterdichte woning
        
      </p>
      <h1 class="text-center"><br /> ________</h1>
    </section>
  </div>

  <!-- Projecten -->
  <section>
    <h1 class="text-center alata pt">
      Projecten
    </h1>

    <div class="projects">
      <div class="projects">
        <app-short-project *ngFor="let project of activeProjects" [project]="project"></app-short-project>
      </div>   
    </div>
  </section>
  <!-- <div class=" margin-center"> -->
    <section class="center pt gezocht">
      <h1 class="text-center alata pb">________ 
        <!--Bouwgrond gezocht!-->
      </h1>
      <img src="assets/images/banner-ontwikkelen-uw-grond.jpg" class="">
      <p class="text-center pt50">Als projectontwikkelaar zijn wij voortdurend in de weer gronden te zoeken om hun
        optimale bestemming te geven.<br />
        Heeft u grond dat u bebouwd of (her)ontwikkeld wilt zien? <br />
        Bel ons gerust even. Dan bekijken we samen de mogelijkheden van uw eigendom.
      </p>
      <div class="justify-content-center mt50">
        <a routerLink="/"><button>Contacteer ons</button></a>
      </div>
      <h1 class="text-center pt50">________</h1>
    </section>
   <!--  </div> -->

  <section id="verkocht">
    <div class="projects pt">
      <h1 class="center vw-50 text-center alata pb25">
        Recente realisaties</h1>
        <div class="projects">
          <app-short-project *ngFor="let project of soldProjects" [project]="project" [isSold]="true"></app-short-project>
        </div>      
    </div>
  </section>

  <app-vraag-project></app-vraag-project>

  <!-- MailChimp -->
  <div>
  </div>
</div>

