<nav class="flex-container c-menu">

    <div class="c-menu__logo flex">
      <a href="#">
        <img src="assets/images/BeauHouse_rounded.png" class="c-logo">
      </a>
    </div>
    <div class="toggle" (click)="toggle()">
      <a href="" *ngIf="isMenuActive" class="menuToggle red">X</a>
      <a *ngIf="!isMenuActive" class="menuToggle">
        <span></span>
        <span></span>
        <span class="line-red"></span>
        <span class="line-blue"></span>
      </a>
    </div>

  <div class="c-menu__list" [class.active]="isMenuActive">
    <ul class="menu">
            <li class="item"><a href="/admin/listprojects">Overzicht projecten</a></li>
            <li class="item"><a target="_blank" routerLink="">Voorbeeld website</a></li>
            <li class="item"><a routerLink="">Uw profiel</a></li>
            <li class="item"><a routerLink="">Uitloggen</a></li>
    </ul>
  </div>
</nav>

<!-- hier komen de effectieve pagina's -->
<router-outlet></router-outlet>
<div>
  <i class="fa-solid fa-circle-user"></i>
</div>
<!-- Footer -->
<app-footer></app-footer>