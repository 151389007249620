<nav class="flex-container c-menu">

    <div class="c-menu__logo flex">
      <a routerLink="/homepagina">
        <img src="assets/images/BeauHouse_rounded.png" class="c-logo">
      </a>
    </div>

    <div class="toggle" (click)="toggle()">
      <a *ngIf="isMenuActive" class="menuToggle red">X</a>
      <a *ngIf="!isMenuActive" class="menuToggle">
        <span></span>
        <span></span>
        <span class="line-red"></span>
        <span class="line-blue"></span>
      </a>
    </div>

  <div class="c-menu__list" [class.active]="isMenuActive">
    <ul class="menu" (click)="toggle()">
      <li class="item"><a routerLink="" >Home</a></li>
      <li class="item"><a routerLink="/project/overzicht" routerLinkActive="active-link" >Woonprojecten</a></li>
      <li class="item"><a routerLink="/projectontwikkeling" routerLinkActive="active-link" >Projectontwikkeling</a></li>
      <li class="item"><a routerLink="/project/sold-projects" routerLinkActive="active-link" >Realisaties</a></li>
      <li class="item"><a routerLink="/about" routerLinkActive="active-link" >Over ons</a></li>
      <li class="item"><a routerLink="/contact" routerLinkActive="active-link" >Contact</a></li>
    </ul>
  </div>
</nav>

<!-- hier komen de effectieve pagina's -->
<router-outlet></router-outlet>

<!-- Footer -->
<app-footer></app-footer>